.form {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding: 10rem 2rem 5rem 2rem;

  width: 50%;
}

.form_header,
.button {
  width: 100%;
  display: block;
  font-family: "BPG Arial Caps", sans-serif;
  font-size: 2rem;
  letter-spacing: 0.2rem;
  color: var(--text-main-color);
  line-height: 2;
  font-weight: 400;
}
.label {
  width: 100%;
  display: block;
  font-family: "BPG Arial Caps", sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
  color: var(--text-main-color);
  line-height: 2;
  font-weight: 400;
  display: flex;
}

.status {
  width: 100%;
  height: 3rem;
  display: block;
  font-family: "BPG Arial Caps", sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
  color: var(--text-main-color);
  line-height: 2;
  font-weight: 400;
  display: flex;
}
.button,
.input {
  display: flex;
  width: 100%;
  border-radius: 8px;

  color: var(--text-color);
  font-size: 16px;
  padding: 1.5rem 3rem;
  text-decoration: none;
  transition: all 0.4s;

  font-family: "BPG Arial Caps", sans-serif;
}
.input {
  border: solid var(--secondary-color) 1px;
}

.button {
  border: solid var(--border-color) 1px;
  justify-content: center;
  letter-spacing: 0.5rem;
}

.input:focus {
  border: solid var(--main-color) 1px;
}

@media screen and (max-width: 1200px) {
  .form {
    width: 100%;
    justify-content: center;
    padding: 1.6rem;
  }
  .button,
  .label,
  .input {
    justify-content: center;
  }
}
