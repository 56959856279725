* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  --main-color: #6c5e48;
  --secondary-color: #fffaed;
  --border-color: #382505;
  --text-main-color: #5c4e4e;
}

html {
  overflow: scroll;
  font-size: 62.5%;
  line-height: 1;
  font-weight: 400;
  font-family: Rubik;
  text-decoration: none;
  color: var(--text-main-color);
  scroll-behavior: smooth;
  background-color: var(--secondary-color);
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@400;500;600;700&family=Rubik:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "BPG Nino Mtavruli";
  src: url(../node_modules/bpg-nino-mtavruli/fonts/bpg-nino-mtavruli-webfont.eot);
  src: url(../node_modules/bpg-nino-mtavruli/fonts/bpg-nino-mtavruli-webfont.eot?#iefix)
      format("embedded-opentype"),
    url(../node_modules/bpg-nino-mtavruli/fonts/bpg-nino-mtavruli-webfont.woff2)
      format("woff2"),
    url(../node_modules/bpg-nino-mtavruli/fonts/bpg-nino-mtavruli-webfont.woff)
      format("woff"),
    url(../node_modules/bpg-nino-mtavruli/fonts/bpg-nino-mtavruli-webfont.ttf)
      format("truetype"),
    url(../node_modules/bpg-nino-mtavruli/fonts/bpg-nino-mtavruli-webfont.svg#bpg_nino_mtavruliregular)
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "BPG Arial Caps";
  src: url(../node_modules/bpg-arial-caps/fonts/bpg-arial-caps-webfont.eot);

  src: url(../node_modules/bpg-arial-caps/fonts/bpg-arial-caps-webfont.eot?#iefix)
      format("embedded-opentype"),
    url(../node_modules/bpg-arial-caps/fonts/bpg-arial-caps-webfont.woff2)
      format("woff2"),
    url(../node_modules/bpg-arial-caps/fonts/bpg-arial-caps-webfont.woff)
      format("woff"),
    url(../node_modules/bpg-arial-caps/fonts/bpg-arial-caps-webfont.ttf)
      format("truetype"),
    url(../node_modules/bpg-arial-caps/fonts/bpg-arial-caps-webfont.svg#bpg_arial_capsregular)
      format("svg");
  font-display: swap;
}
