/***** Header  styles *****/
/****************************/

.header_container {
  max-width: 192rem;
  height: 8rem;

  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 2rem;
  margin: 0 auto;
  background-color: var(--main-color);

  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
  position: relative;
}

.sticky {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  height: 8rem;
  z-index: 999;
}

.slogan {
  display: flex;
  align-items: center;
  height: 100%;
}

.slogan a:link,
.slogan a:visited {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;

  text-decoration: none;
  color: var(--secondary-color);
  font-size: 3.2rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
  transition: all 0.5s;
}

.slogan a:hover,
.slogan a:active {
  color: var(--main-color);
}
.slogan img {
  height: 90%;
}

/*navigation*/

.main_nav {
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.5s;
}

.main_nav_link {
  list-style-type: none;
}

.main_nav_link:link,
.main_nav_link:visited {
  display: flex;
  align-items: center;
  height: 8rem;
  padding: 0 2rem;

  text-decoration: none;
  color: var(--secondary-color);
  font-size: 1.6rem;
  letter-spacing: 0.3rem;

  border-top: 3px solid var(--main-color);
  border-bottom: 3px solid var(--main-color);
  font-family: "BPG Arial Caps", sans-serif;
  font-weight: 600;

  transition: all 0.5s;
}

.main_nav_link:hover,
.main_nav_link:active {
  border-top: 3px solid var(--border-color);
  border-bottom: 3px solid var(--border-color);
}

/*Language switcher*/

.custom_select {
  padding: 1rem 2rem;

  cursor: pointer;
  font-size: 2rem;
  /* background-color: var(--main-color); */
  border: 2px solid var(--border-color);
  color: var(--border-color);
  font-family: "BPG Arial Caps", sans-serif;
  text-transform: uppercase;
  /* box-shadow: 0px 0px 2px 3px var(--border-color); */
  max-height: 8rem;
  border-radius: 1rem;
  transition: all 0.5s;
}
.custom_select_option {
  margin: 1rem 2rem;
}
.custom_select_option:hover {
  background-color: var(--secondary-color);
}

/*mobile navigation*/

.btn_mobile_nav {
  padding: 1rem;
  cursor: pointer;
  background: none;

  border: none;
  color: var(--secondary-color);
  display: none;
  z-index: 9999;
}

.icon_mobile_nav {
  height: 4.8rem;
  width: 4.8rem;
}

.icon_mobile_nav[name="close-outline"] {
  display: none;
}

/***** Footer  styles *****/
/****************************/

.footer_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;

  min-height: 30rem;
  background-color: var(--main-color);
  color: var(--secondary-color);
}

.footer_container span {
  font-family: "Rubik", sans-serif;
}

.footer_copyright,
.footer_mob {
  font-family: "BPG Arial Caps", sans-serif;
  width: fit-content;
  border: none;
  text-decoration: none;
  font-size: 1.6rem;
  color: var(--secondary-color);
  line-height: 1.8;
  transition: all 0.5s;
}

.footer_mob {
  margin-top: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  padding: 1.6rem 3.2rem;
  letter-spacing: 0.5rem;
}

.footer_mob:hover {
  border: 1px solid var(--secondary-color);
  box-shadow: 0px 0px 4px 2px var(--border-color);
}

/***** Media Queries *****/
/****************************/
@media screen and (max-width: 1070px) {
  .header_container {
    height: 11rem;
  }
}

@media screen and (max-width: 830px) {
  .header_socials {
    display: none;
  }

  .main_nav {
    background-color: var(--main-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    flex-direction: column;
    gap: 1rem;
    transform: translateX(100%);
    transition: all 0.5s ease-out;

    /*hide navigation with transition*/
    opacity: 0; /* hide visually*/
    pointer-events: none; /* unaccessible to mouse and keyboard*/
    visibility: hidden; /*hide fro screen readers*/
  }

  .nav_open .main_nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav_open .icon_mobile_nav[name="close-outline"] {
    display: block;
  }

  .nav_open .icon_mobile_nav[name="menu-outline"] {
    display: none;
  }

  .btn_mobile_nav {
    display: block;
  }

  .main_nav_link:link,
  .main_nav_link:visited {
    border: none;
    font-size: 3.2rem;
    gap: 2rem;
  }

  .custom_select {
    font-size: 2.4rem;
    min-height: 5rem;
  }

  .footer_socials {
    display: block;
  }

  .footer_container {
    min-height: 25rem;
  }
}
