.galery {
  width: 100%;
  margin: 8rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container {
  margin: 0 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.image_card {
  padding: 1rem;
  border-radius: 3px;
}

.image {
  width: 100%;
}

.tags {
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

.tag {
  outline: none;
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  border-radius: 1rem;
  border: 1px solid var(--secondary-color);
}

.tag:hover,
.tag.active {
  border: 1px solid var(--secondary-color);
  box-shadow: 0px 0px 4px 2px var(--border-color);
}
.active {
  background-color: var(--main-color);
  color: var(--secondary-color);
}

@media screen and (max-width: 830px) {
  .galery {
    margin: 11rem 1rem 1rem 1rem;
  }

  .container {
    grid-template-columns: repeat(3, 1fr);
  }

  .tags {
    padding: 0.5rem;
  }

  .tag {
    font-size: 1.4rem;
    padding: 1.6rem 1rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }

  .tags {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
