/***** HomePage  styles *****/
/****************************/

.home_section {
  max-width: 192rem;
  margin: 0 auto 3rem 0;
  padding-top: 8rem;
  display: flex;
  flex-direction: column;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home_text_container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  min-height: 43.8rem;
  /* margin-bottom: 8rem; */
  background-color: var(--secondary-color);

  /* offset-x | offset-y | blur-radius | color */
  /* text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); */
}

.welcome_texts {
  display: flex;
  flex-direction: column;
  padding-left: 6rem;
  width: 100%;
  gap: 1rem;
  text-transform: uppercase;
  /* padding: 4.8rem 6.4rem; */
}

.welcome_texts h1 {
  width: 100%;
  border-top: 2px solid var(--main-color);
  border-bottom: 2px solid var(--main-color);
  width: fit-content;
  margin-bottom: 2rem;

  display: inline-block;

  font-family: "BPG Arial Caps", sans-serif;
  font-size: 3.6rem;
  line-height: 1.6;
  font-weight: 400;
  text-transform: uppercase;

  color: var(--text-main-color);
}
.welcome_texts h2,
.welcome_texts p {
  width: 100%;

  font-family: "BPG Arial Caps", sans-serif;
  font-size: 2rem;

  line-height: 2;
  font-weight: 400;
  text-transform: uppercase;

  color: var(--text-main-color);
}

.contact_btn {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "BPG Arial Caps", sans-serif;
  width: fit-content;
  padding: 2.4rem 3.2rem;

  margin-top: 1.6rem;

  cursor: pointer;
  background-color: var(--main-color);

  font-size: 1.6rem;
  color: var(--secondary-color);
  letter-spacing: 0.5rem;

  text-decoration: none;
  text-align: center;

  border: 1px solid var(--main-color);
  border-radius: 8px;

  transition: all 0.5s;
  min-width: 30rem;
}
.contact_btn_icon {
  margin-left: 1rem;
}

.contact_btn:hover {
  /* color: var(--text-main-color);
  background-color: var(--secondary-color); */
  /* border: 2px solid var(--border-color); */

  border: 1px solid var(--secondary-color);
  box-shadow: 0px 0px 4px 2px var(--border-color);
}

.cover {
  width: 81rem;
  height: 50rem;

  background-image: linear-gradient(
      90deg,
      var(--secondary-color) 2%,
      #ffffff0e 25%
    ),
    url("../img/background.webp");

  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

/***** Services styles *****/
/****************************/

.services_section {
  display: flex;
  justify-content: space-around;

  align-items: center;
  min-height: 60rem;
  padding: 3rem;
  background-color: var(--secondary-color);
}

.services_icon {
  width: 3.6rem;
  height: 3.6rem;

  margin-right: 5rem;
  stroke: var(--main-color);
}

.services_list_conainer {
  display: flex;
  flex-direction: column;

  font-family: "BPG Arial Caps", sans-serif;
  color: var(--text-main-color);
  line-height: 1.8;
  max-width: 70rem;
}

.services_list_conainer ul {
  text-align: right;
  padding-left: 3rem;
  border-left: 5px solid var(--main-color);
  max-width: 40rem;
}

.banks {
  font-size: 1.2rem;
}
.services_list_conainer p {
  font-size: 3.2rem;
  justify-self: right;
}

.services_list_conainer li {
  font-size: 2.4rem;
  list-style: none;
  border-bottom: 1px solid var(--main-color);
  transition: all 0.3s;
  margin: 2rem 0;
}

.services_list_conainer li:hover {
  padding-right: 3rem;
}

.video {
  width: 70rem;
  height: 50rem;

  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  border: none;
}

/***** Projects styles *****/
/****************************/

.projects_section {
  margin-top: 3rem;
  padding: 3.2rem 4.2rem;
  min-height: 50rem;
}

.project_header {
  text-align: center;
  font-family: "BPG Arial Caps", sans-serif;
  font-size: 3.2rem;

  line-height: 2;
  font-weight: 400;
  margin: 2rem;

  color: var(--text-main-color);
}

.galery {
  display: flex;
  justify-content: center;
  align-items: center;
}
.galery_img_box {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.galery_img {
  max-height: 35rem;
  width: auto;
  transition: all 0.3s;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.galery_img:hover {
  transform: scale(1.05);
}

/*responsible*/

@media screen and (max-width: 1385px) {
  .welcome_texts h1 {
    margin-bottom: 1rem;
    font-size: 2.8rem;
    line-height: 1.4;
  }
  .welcome_texts h2 {
    font-size: 1.6rem;

    line-height: 1.6;
    font-weight: 400;
  }

  .contact_btn {
    letter-spacing: 0.2rem;
  }
}
@media screen and (max-width: 1300px) {
  html {
    font-size: 55%;
  }
  .welcome_texts {
    padding-left: 2rem;
  }
  .galery_img {
    max-height: 40rem;
  }

  .services_list_conainer li {
    font-size: 2rem;
  }
  .services_list_conainer p {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 1070px) {
  html {
    font-size: 50%;
  }

  .home_section {
    padding-top: 11rem;
  }
  .galery_img {
    max-height: 45rem;
  }
}

@media screen and (max-width: 990px) {
  .cover {
    width: 71rem;
    height: 43.8rem;
  }
}

@media screen and (max-width: 970px) {
  .video {
    width: 50rem;
  }
}

@media screen and (max-width: 840px) {
  .welcome_texts {
    gap: 0.5rem;
  }

  .welcome_texts h1 {
    margin-bottom: 0.5rem;
    font-size: 2.4rem;
    line-height: 1.2;
  }
  .welcome_texts h2 {
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: 400;
  }

  .contact_btn {
    letter-spacing: 0.1rem;
    padding: 1.4rem 1.6rem;
  }
}

@media screen and (max-width: 775px) {
  .home_text_container {
    min-height: 60rem;
  }

  .cover {
    display: none;
  }

  .form_header {
    width: 100%;
  }

  .welcome_texts {
    text-align: center;
    justify-items: center;
    gap: 2rem;
  }

  .welcome_texts h1 {
    margin: auto;
    width: 90%;
    margin-bottom: 1rem;
    font-size: 3.2rem;
    line-height: 1.6;
  }

  .welcome_texts h2 {
    font-size: 2rem;

    line-height: 1.4;
    font-weight: 400;
  }

  .contact_btn {
    letter-spacing: 0.2rem;
    padding: 1.6rem 2.4rem;
    margin: auto;
  }
  .video {
    width: 45rem;
  }
  .project_header {
    font-size: 2.4rem;
  }
  .services_section {
    flex-direction: column;
    padding: 4.8rem 1.6rem;
    gap: 4.8rem;
  }
}
