.social_container {
  display: flex;
  gap: 2rem;
}
.social_icons {
  width: 5rem;
  height: 5rem;
  fill: var(--secondary-color);
  border: 1px solid var(--border-color);
  padding: 1rem;
  cursor: pointer;
  border-radius: 4px;

  transition: all 0.5s;
}

.social_icons:hover {
  border: 1px solid var(--secondary-color);
  box-shadow: 0px 0px 4px 2px var(--border-color);
}
